const faq_item = document.querySelectorAll(".faq-item");

faq_item.forEach(function (item) {
	const faq_header = item.querySelector(".faq-item__head");
	const faq_content = item.querySelector(".faq-item__content");

	faq_header.addEventListener("click", function () {
		
		if(faq_header.classList.contains("active")) {
			faq_content.classList.add("hidden");
			faq_header.classList.remove("active");
		} else {
			faq_content.classList.remove("hidden");
			faq_header.classList.add("active");
		}

	});
});
