if (document.querySelector(".bigtext")) {
	document.addEventListener("scroll", function () {
		if (window.innerWidth < 768) {
			document.querySelector(".bigtext").style.transform = `translateX( ${
				window.scrollY / 10 - 350
			}px`;
		} else {
			document.querySelector(".bigtext").style.transform = `translateX( ${
				window.scrollY / 10 - 150
			}px`;
		}
	});
}
