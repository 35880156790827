import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper'
import 'swiper/css';
import "swiper/css/navigation";
Swiper.use( [ Navigation, Autoplay ] );

const swiper = new Swiper(".swiper", {
	// Optional parameters
	direction: "horizontal",
	slidesPerView: 1,
	centeredSlides: true,
	autoplay: {
		delay: 4000,
	},
	breakpoints: {
		768: {
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
		},
	},
});

const imageswiper = new Swiper(".image-swiper", {
	// Optional parameters
	direction: "horizontal",
	slidesPerView: 1,
	autoplay: {
		delay: 4000,
	},
	spaceBetween: 15,
	breakpoints: {
		768: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
		},
	},
});
