import crumbs from 'crumbsjs';
// const JSAlert = require('js-alert');
import JSAlert from 'js-alert'

var x, i, j, l, ll, selElmnt, a, b, c;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName("langswitch-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /* For each element, create a new DIV that will act as the selected item: */
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  a.classList.add(selElmnt.options[selElmnt.selectedIndex].dataset.optionClass);
  a.classList.add('flag-' + selElmnt.options[selElmnt.selectedIndex].value);
  a.dataset.optionClass = selElmnt.options[selElmnt.selectedIndex].dataset.optionClass;

  let old_lang = a.innerHTML.toLowerCase();
  old_lang = old_lang.replace(/\s/g, '');

  x[i].appendChild(a);
  /* For each element, create a new DIV that will contain the option list: */
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;

    c.classList.add(selElmnt.options[j].dataset.optionClass);
    c.classList.add('flag-' + selElmnt.options[j].value);
    c.dataset.optionClass = selElmnt.options[j].dataset.optionClass;

    c.addEventListener("click", async function (e) {
      /* When an item is clicked, update the original select box,
      and the selected item: */
      var y, i, k, s, h, sl, yl, lg;

      s = this.parentNode.parentNode.getElementsByTagName("select")[0]; // dit is de hele lijst

      sl = s.length;

      h = this.parentNode.previousSibling; // dit is de nieuwe

      lg = this.dataset.optionClass;

      for (i = 0; i < sl; i++) {

        if (s.options[i].innerHTML == this.innerHTML) {
          let currenturl = document.querySelector('#currenturl').value;
          let postid = document.querySelector('#postid').value;
          let posttype = document.querySelector('#posttype').value;
          let pagetype = document.querySelector('#pagetype').value;
          let old_lang = document.querySelector('#old_lang').value;

          await changeCurrentLanguage(lg.substring(lg.length - 2), old_lang, currenturl, postid, posttype, pagetype)
            .then((result) => {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].classList.remove("same-as-selected");
                if (a.classList.contains(h.dataset.optionClass)) {
                  a.classList.remove(h.dataset.optionClass)
                }
              }
              this.classList.add("same-as-selected");
              this.classList.add(lg);
              this.classList.add('flag-' + s.options[i].value);

              a.dataset.optionClass = lg;
              a.classList.add(lg);
              window.location = result.url;
            })
            .catch((error) => {
              document.querySelector('body').classList.remove('loading-language');
              JSAlert.alert(error.message);
            })

          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    e.stopPropagation();
    closeAllSelect(this, true);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");
  });
}

function closeAllSelect(elmnt, fromInside = false) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener("click", closeAllSelect);

/**
 * jQuery function that sends AJAX call to PHP where WPML changes language.
 */
async function changeCurrentLanguage(new_lang, old_lang, currenturl, postid, posttype, pagetype) {
  return new Promise((resolve, reject) => {

    new_lang = new_lang.replace(/\s/g, "");

    let payload = {
      lang: new_lang,
      old_lang: old_lang,
      currenturl: currenturl,
      postid: postid,
      posttype: posttype,
      pagetype: pagetype
    };

    jQuery.ajax({
      type: 'POST',
      url: wpml_ajax.ajaxurl,
      data: {
        action: 'change_current_language_page',
        payload: payload
      },
      beforeSend: () => {
        document.querySelector('body').classList.add('loading-language');
      },
      success: (response) => {
        setLanguageCookie('wp-wpml_current_language', new_lang, 30, '/');
        setLanguageLocalStorage('wp-wpml_current_language', new_lang);
        if (response.message === 'No change') {
          reject(response);
        } else {
          if (response.hasOwnProperty('url')) {
            if (response.url !== 'undefined' && response.url !== undefined) {
              if (response.url.includes('page_id')) {
                reject(response);
              } else {
                resolve(response);
              }
            } else {
              reject('This page has no translation, yet.');
            }
          } else {
            if (response.message === 'No translation exists for this page.') {
              reject(response);
            } else {
              reject(response)
            }
          }
        }
      },
      error: (error) => {
        reject(error);
      }
    });

  })
}

function setLanguageCookie(name, value, days, path) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  const cookieValue = `${name}=${value};expires=${expires.toUTCString()};path=${path}`;
  document.cookie = cookieValue;
}

function setLanguageLocalStorage(key, value) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value);
  } else {
    // Fallback to cookies if localStorage is not available
    setCookie(key, value, 30, '/');
  }
}