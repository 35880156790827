const cta_mob = document.querySelectorAll(".cta__mob");
const close = document.querySelector(".cta__mob__close");

cta_mob.forEach(function (item) {
	const cta_tel_icon = item.querySelector('.tel')
	const cta_mobitem = item.querySelector(".cta__mob-item:nth-child(1)");
	const cta_mobitem_second = item.querySelector(".cta__mob-item:nth-child(2)");
	const cta_mobitem_third = item.querySelector(".cta__mob-item:nth-child(3)");
	const cta_mobclick = item.querySelector(".cta__mob-click");

	cta_mobclick.addEventListener("click", function () {
		if(cta_mobclick.classList.contains("active")) {
			if (cta_mobclick) { cta_mobclick.classList.remove("active"); }
			if (cta_mobitem_second) { cta_mobitem_second.classList.add("hidden"); }
			if (cta_mobitem_third) { cta_mobitem_third.classList.add("hidden"); }
			cta_mobitem.classList.add("hidden");
			close.classList.add('hidden');
			cta_tel_icon.classList.remove('hidden');

		} else {
			if (cta_mobitem) { cta_mobitem.classList.remove("hidden"); }
			if (cta_mobitem_second) { cta_mobitem_second.classList.remove("hidden"); }
			if (cta_mobitem_third) { cta_mobitem_third.classList.remove("hidden"); }
			cta_mobclick.classList.add("active");
			close.classList.remove('hidden');
			cta_tel_icon.classList.add('hidden');

		}

	});
});
