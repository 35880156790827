function setupTrainingSelectionListeners() {
    const trainingIdElement = document.querySelector('[data-name="training_id"]');
    let currentAvailableSpots = 0; // Keep track of the current available spots for the selected training


    function updateColleaguesSectionAvailability(radioChangeEvent = false) {
        const collegasRows = document.querySelectorAll('[data-name="collegas"] .acf-row:not(.acf-clone)');
        const table = document.querySelector('[data-name="collegas"] .acf-table');
        const collegasRowsLength = collegasRows.length;
        const addButton = document.querySelector('[data-name="collegas"] .acf-actions');
        addButton.removeAttribute('href');
        const messageContainer = document.querySelector('#collegasElement') || document.createElement('div');


        if(currentAvailableSpots === 1) {
            if (!document.querySelector('#collegasElement')) {
                messageContainer.id = 'collegasElement';
                messageContainer.classList.add('training-collegas-message');
                messageContainer.textContent = 'Deze training heeft niet genoeg beschikbare plekken voor meer collega\'s.';
                addButton.parentNode.insertBefore(messageContainer, addButton.nextSibling);
                table.classList.add('disabled');
            }
        } else {
            table.classList.remove('disabled');
        }

        if (collegasRowsLength + 1 > currentAvailableSpots) {
            addButton.disabled = true;
            addButton.classList.add('disabled');


            if (!document.querySelector('#collegasElement')) {
                messageContainer.id = 'collegasElement';
                messageContainer.classList.add('training-collegas-message');
                messageContainer.textContent = 'Deze training heeft niet genoeg beschikbare plekken voor meer collega\'s.';
                addButton.parentNode.insertBefore(messageContainer, addButton.nextSibling);
            }

            // Remove excess rows only if the change was triggered by a radio button change
            if (radioChangeEvent) {
                for (let i = currentAvailableSpots; i < collegasRowsLength; i++) {
                    collegasRows[i].remove()
                }
            }

        } else {
            addButton.disabled = false;
            addButton.classList.remove('disabled');
            if (document.querySelector('#collegasElement')) {
                document.querySelector('#collegasElement').remove();
            }
        }
    }

    function handleTrainingCategorySelection() {
        // Clear any existing voorwaardenElement
        const existingVoorwaardenElement = document.querySelector('#voorwaardenElement');
        if (existingVoorwaardenElement) {
            existingVoorwaardenElement.remove();
        }

        const selectedRadio = trainingIdElement.querySelector('input[type="radio"]:checked');
        if (selectedRadio) {
            const value = parseInt(selectedRadio.value);
            const trainingCategory = trainingCategories.find(item => item.id === value);
            currentAvailableSpots = trainingCategory ? trainingCategory.beschikbare_plekken : 0;
            updateColleaguesSectionAvailability(true);

            if (trainingCategory && trainingCategory.voorwaarden) {
                const voorwaardenElement = document.createElement('div');
                voorwaardenElement.id = 'voorwaardenElement';
                voorwaardenElement.classList.add('training-voorwaarden');
                voorwaardenElement.innerHTML = trainingCategory.voorwaarden;
                trainingIdElement.appendChild(voorwaardenElement);
            }
        }
    }

    function appendInschrijvingenOnLoad() {
        const radioButtons = trainingIdElement.querySelectorAll('input[type="radio"]');
        radioButtons.forEach(radio => {
            const inschrijvingenElement = document.createElement('div');
            inschrijvingenElement.className = 'training-inschrijvingen';
            const value = parseInt(radio.value);
            const trainingCategory = trainingCategories.find(item => item.id === value);
            if (trainingCategory && trainingCategory.beschikbare_plekken === 0) {
                radio.parentNode.parentNode.style.display = 'none';
                radio.disabled = true;
            }
            if (trainingCategory && trainingCategory.beschikbare_plekken) {
                inschrijvingenElement.textContent = `${trainingCategory.beschikbare_plekken} plekken beschikbaar`;
                radio.parentNode.appendChild(inschrijvingenElement);
            }
        });
    }

    const addColleaguesEl = document.querySelector('[data-name="collegas"] .acf-table');
    if (addColleaguesEl) {
        const observer = new MutationObserver((mutationsList, observer) => {
            // Flag to check if at least one row was added
            let rowAdded = false;
            
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    // Check if nodes were added and no nodes were removed
                    if (mutation.addedNodes.length > 0 && mutation.removedNodes.length === 0) {
                        rowAdded = true;
                        break; // Exit the loop early if we've found an addition
                    }
                }
            }
            
            // Call the function only if a row was indeed added
            if (rowAdded) {
                updateColleaguesSectionAvailability();
            }
        });

        observer.observe(addColleaguesEl, { childList: true, subtree: true });
    }


    if (trainingIdElement) {
        trainingIdElement.addEventListener('change', handleTrainingCategorySelection);
        document.addEventListener('DOMContentLoaded', () => {
            appendInschrijvingenOnLoad();
            // Ensure initial setup doesn't assume it's a radio change
            handleTrainingCategorySelection(false);
        });
    }
}
setupTrainingSelectionListeners();