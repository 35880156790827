const toggle = document.querySelector("#mobile-menu-click");
const mobile_menu = document.querySelector(".mobile-menu");
const mobile_menu_text = document.querySelector(".mobile-menu-text");

if (toggle) {
	toggle.addEventListener("click", function () {
		mobile_menu.classList.toggle("-translate-y-full");
		// mobile_menu.classList.toggle("z-10");
		mobile_menu_text.classList.toggle("text-active");
		if (mobile_menu_text.classList.contains("text-active")) {
			mobile_menu_text.innerText = "sluit menu";
		} else {
			mobile_menu_text.innerText = "menu";
		}
	});
}

var header = document.querySelector(".header");
var sticky = header ? header.offsetTop : 0;

function toggleStickyClass() {
	if (window.pageYOffset > sticky) {
		header.classList.add("sticky");
	} else {
		header.classList.remove("sticky");
	}
}

window.onscroll = function () {
	if (header) {
		toggleStickyClass();
	}
};