const video_box = document.querySelectorAll(".videobox");

video_box.forEach(function (item) {
	const video = item.querySelector("video");
	const video_play = item.querySelector(".videoplay");
	const video_bg = item.querySelector(".videobg");
	const video_img = item.querySelector(".videoimage");
	if (video) {
		video_play.addEventListener("click", function () {
			video_play.classList.add("hidden");
			video_bg.classList.add("hidden");
			video_img.classList.add("hidden");
			video.play();
		});
		video_bg.addEventListener("click", function () {
			video_play.classList.add("hidden");
			video_bg.classList.add("hidden");
			video_img.classList.add("hidden");
			video.play();
		});
	}
});
