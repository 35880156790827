/**
 * Helpers
 */
import { fromBreakpoint, onVisibilityChange, toBreakpoint } from "./helpers";

/**
 * Web standards
 */
import "./cookieconsent";
import "./langswitcher";
import "./softscroll";
import "./lazyload";
import "alpinejs/dist/cdn.min.js";
import "./text-scroll";
import "./header.js";
import "./popup.js";
import "./sliders";
import "./video.js";
import "./faq.js";
import "./cta.js";
import "./trainingen.js";

if (document.querySelector(".masonry-section")) {
	import("./postgrid.js");
}
/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector(".wpcf7-form");
if (wpcf7forms) {
	const jquery = import("jquery");
	global.jQuery = require("jquery");
	window.jQuery = require("jquery");
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener("DOMContentLoaded", () => {
	if (document.querySelectorAll("div[data-modal-target]").length > 0) {
		modal.init();
	}
});

/**
 * Dynamic import scripts
 */
const elements = [
	// '#header-video'
	"#project_swiper",
  '.wpcf7'
];

[].forEach.call(elements, (element) => {
	if (element !== "") {
		if (
			document.querySelector(element) !== null &&
			document.querySelector(element) !== undefined
		) {
			const lazyloadHandler = onVisibilityChange(
				document.querySelector(element),
				function () {
					if ( element === '.wpcf7' ) {
						const cf7scripts = import('./cf7');
					}
					if (element === "#project_swiper") {
						const swiper = import("./project.swiper");
					}
				}
			);

			if (window.addEventListener) {
				addEventListener("DOMContentLoaded", lazyloadHandler, false);
				addEventListener("load", lazyloadHandler, false);
				addEventListener("scroll", lazyloadHandler, false);
				addEventListener("resize", lazyloadHandler, false);
			} else if (window.attachEvent) {
				attachEvent("onDOMContentLoaded", lazyloadHandler); // Internet Explorer 9+ :(
				attachEvent("onload", lazyloadHandler);
				attachEvent("onscroll", lazyloadHandler);
				attachEvent("onresize", lazyloadHandler);
			}
		}
	}
});
